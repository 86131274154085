import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CHANNEL,
  EPISODE,
  PODCAST,
  PODCAST_EPISODE,
  PODCAST_SEASON,
  SERIES,
  STANDALONE_PROGRAM
} from '../../../pages/FrontPage/helpers/plugTypes'
import { focusVisibleStyles } from '../../Plugs/styles/focusStyles'
import { useColors } from '../../theme/Color'
import Text from '../../Text'
import Box from '../../Box'
import { css } from '@emotion/react'
import CustomButton from '../../CustomButton'
import Icon from '../../Icon'
import PlugProgressCircle from './PlugProgressCircle'
import { usePlayerElement } from '../../../components/StickyPlayer/context/PlayerElementContext'
import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'
import { useIsPlayerPlaying } from '../../../components/StickyPlayer/context/PlayerElementStateContext'
import { useCurrentlyPlayingId } from '../../../components/StickyPlayer/context/PlayerMetadataContext'
import { fetchCatalogPodcastSeasonEpisodes, fetchCatalogSeasonEpisodes } from '../../../apiClients/psapi'
import { fetchProgramProgressById } from '../../../apiClients/personalisation'
import { durationToSeconds } from '../../../common/parseIsoDuration'
import formatSeconds from '../../../common/formatSeconds'
import SORTS from '../../../pages/Series/helpers/sorts'
import { usePlayerTime } from '../../../components/StickyPlayer/context/PlayerTimeContext'
import { useIsLoggedIn } from '../../../components/Auth/AuthContext'
import { Link } from 'react-router-dom'
import PseudoBox from '../../PseudoBox'
import PlugLink from '../PlugLink/PlugLink'

const noop = () => {}

const hoverStyle = css`
  @media all and (min-width: 992px) {
    .PlayPauseProgressButton {
      transform: scale(0.9643);
    }
  }
  .PlayPauseProgressButton {
    transform: scale(0.9584);
  }
  .PlayPauseProgressButtonText {
    opacity: 1;
  }
`

const toMediaType = type => {
  switch (type) {
    case PODCAST_EPISODE:
      return MEDIA_TYPES.PODCAST
    case PODCAST:
      return MEDIA_TYPES.PODCAST
    case PODCAST_SEASON:
      return MEDIA_TYPES.PODCAST
    case CHANNEL:
      return MEDIA_TYPES.CHANNEL
    default:
      return MEDIA_TYPES.PROGRAM
  }
}

const PlugPlayPauseProgressStaticButton = ({
  type,
  episodeId,
  channelId,
  seriesId,
  seasonId,
  accessibilityLabel,
  startTime,
  linkUrl,
  contentType,
  percentage = 0,
  finished = false,
  onPlayClick = noop,
  onClick = noop, // prevent tracking wrapper from applying onClick
  ...restProps
}) => {
  const colors = useColors()
  const isLoggedIn = useIsLoggedIn()
  const isPlayerPlaying = useIsPlayerPlaying()
  const currentlyPlayingId = useCurrentlyPlayingId()
  const { loadAndStartOrTogglePlayPause } = usePlayerElement()
  const isMediaItemInPlayer = currentlyPlayingId === episodeId || currentlyPlayingId === channelId
  const isPlaying = isMediaItemInPlayer && isPlayerPlaying
  const episodeFinished = percentage === 95 || finished
  const progressContentType = type.includes('podcast') ? 'podcastepisode' : 'programs'
  const { scrubberValues } = usePlayerTime()
  const [progressInPercent, setProgressInPercent] = useState(0)

  useEffect(() => {
    if (isMediaItemInPlayer) {
      setProgressInPercent(Math.floor(100 * (scrubberValues.playheadPosition / scrubberValues.endPosition)))
    } else {
      setProgressInPercent(0)
    }
  }, [isMediaItemInPlayer, setProgressInPercent, scrubberValues])

  const play = useCallback(
    (type, episodeId, channelId, seriesId) => {
      const contentId = [PODCAST_EPISODE, PODCAST, PODCAST_SEASON].includes(type)
        ? `${seriesId}|${episodeId}`
        : episodeId || seriesId
      if (isLoggedIn) {
        fetchProgramProgressById({ contentId, progressContentType }).then(progress => {
          const progressStartTime = progress.progress === 'inProgress' ? durationToSeconds(progress.inProgress.time) : 0
          loadAndStartOrTogglePlayPause({
            mediaType: toMediaType(type),
            episodeId,
            channelId,
            seriesId,
            seekTo: startTime ? durationToSeconds(startTime) : progress.finished ? 0 : progressStartTime
          })
        })
      } else {
        loadAndStartOrTogglePlayPause({
          mediaType: toMediaType(type),
          episodeId,
          channelId,
          seriesId,
          seekTo: startTime ? durationToSeconds(startTime) : 0
        })
      }
    },
    [isLoggedIn, loadAndStartOrTogglePlayPause, progressContentType, startTime]
  )

  const handleOnClick = useCallback(
    event => {
      if ([PODCAST_EPISODE, EPISODE, STANDALONE_PROGRAM].includes(type)) {
        play(type, episodeId, channelId, seriesId)
      } else if (type === SERIES) {
        fetchCatalogSeasonEpisodes(seriesId, null, { pageSize: 5 }).then(res => {
          if (res?._embedded?.episodes?.length > 0) {
            const episode = res._embedded.episodes.find(episode => episode.availability.status === 'available')
            if (episode) {
              play(type, episode.episodeId, channelId, seriesId)
            }
          }
        })
      } else if (type === PODCAST) {
        fetchCatalogPodcastSeasonEpisodes(seriesId, seasonId, { pageSize: 5 }).then(res => {
          if (res?._embedded?.episodes?.length > 0) {
            const episode = res._embedded.episodes.find(episode => episode.availability.status === 'available')
            if (episode) {
              play(type, episode.episodeId, channelId, seriesId)
            }
          }
        })
      } else if (type === PODCAST_SEASON) {
        fetchCatalogPodcastSeasonEpisodes(seriesId, seasonId, { pageSize: 5, sort: SORTS.ASC }).then(res => {
          if (res?._embedded?.episodes?.length > 0) {
            const episode = res._embedded.episodes.find(episode => episode.availability.status === 'available')
            if (episode) {
              play(type, episode.episodeId, channelId, seriesId)
            }
          }
        })
      } else {
        loadAndStartOrTogglePlayPause({ mediaType: toMediaType(type), episodeId, channelId, seriesId })
      }
      onPlayClick(event)
    },
    [channelId, episodeId, loadAndStartOrTogglePlayPause, onPlayClick, play, seasonId, seriesId, type]
  )

  const ariaLabel = isPlaying
    ? `Pause ${accessibilityLabel || ''}`.trim()
    : `Spill av ${accessibilityLabel || ''}`.trim()

  if (type === 'page') {
    return (
      <PseudoBox
        as={Link}
        d="flex"
        alignItems="center"
        aria-label={ariaLabel}
        to={linkUrl}
        css={focusVisibleStyles}
        _hover={hoverStyle}
        _focus={hoverStyle}
      >
        <Box
          bg={type === CHANNEL ? colors.live.mediumLight10 : colors.main.light}
          color={type === CHANNEL ? colors.live.contrastLight : colors.main.dark}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          zIndex="2"
          padding="0 !important"
          className="PlayPauseProgressButton"
          variant="playpause"
          {...restProps}
        >
          <Icon className="PlayPauseIcon" iconId="nrk-category" color="currentColor" />
        </Box>
        <Text variant="subheadRegular" opacity="0.7" pl="0.5rem" className="PlayPauseProgressButtonText">
          Gå til kategori
        </Text>
      </PseudoBox>
    )
  }

  if (type === 'link') {
    return (
      <PlugLink
        type={type}
        d="flex"
        alignItems="center"
        aria-label={ariaLabel}
        to={linkUrl}
        css={focusVisibleStyles}
        _hover={hoverStyle}
        _focus={hoverStyle}
      >
        <Box
          bg={type === CHANNEL ? colors.live.mediumLight10 : colors.main.light}
          color={type === CHANNEL ? colors.live.contrastLight : colors.main.dark}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          zIndex="2"
          padding="0 !important"
          className="PlayPauseProgressButton"
          variant="playpause"
          {...restProps}
        >
          <Icon className="PlayPauseIcon" iconId="nrk-link" color="currentColor" />
        </Box>
        <Text variant="subheadRegular" opacity="0.7" pl="0.5rem" className="PlayPauseProgressButtonText">
          Åpne lenke
        </Text>
      </PlugLink>
    )
  }

  return (
    <CustomButton
      d="flex"
      alignItems="center"
      variant="onlytext"
      padding="0 !important"
      zIndex="2"
      h={restProps.h}
      aria-label={ariaLabel}
      onClick={handleOnClick}
      css={focusVisibleStyles}
      _hover={hoverStyle}
      _focus={hoverStyle}
    >
      {type !== CHANNEL && (
        <PlugProgressCircle
          percentage={episodeFinished ? 100 : progressInPercent || percentage}
          type={type}
          size={restProps.h}
        />
      )}
      <Box
        bg={type === CHANNEL ? colors.live.mediumLight10 : colors.main.light}
        color={type === CHANNEL ? colors.live.contrastLight : colors.main.dark}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        className="PlayPauseProgressButton"
        variant="playpause"
        {...restProps}
      >
        <Icon
          className="PlayPauseIcon"
          iconId={isPlaying ? 'nrk-media-pause' : 'nrk-media-play'}
          color="currentColor"
        />
      </Box>
      <Text variant="subheadRegular" opacity="0.7" pl="0.5rem" className="PlayPauseProgressButtonText">
        {type === CHANNEL
          ? 'Hør direkte'
          : episodeId
          ? startTime
            ? `Hør fra ${formatSeconds(durationToSeconds(startTime))}`
            : 'Hør episoden'
          : seasonId
          ? 'Hør sesongen'
          : 'Hør serien'}
      </Text>
    </CustomButton>
  )
}

PlugPlayPauseProgressStaticButton.propTypes = {
  type: PropTypes.string,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  linkUrl: PropTypes.string,
  contentType: PropTypes.string,
  percentage: PropTypes.number,
  finished: PropTypes.bool,
  onPlayClick: PropTypes.func,
  startTime: PropTypes.string,
  onClick: PropTypes.func
}

export default PlugPlayPauseProgressStaticButton
