import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import PlugImage from '../../Plug/PlugImage'
import PlugTagline from '../../Plug/PlugTagline'
import PlugTitle from '../../Plug/PlugTitle'
import PlugBody from '../../Plug/PlugBody'
import EditorialTextWrapper from './componentParts/EditorialTextWrapper'
import Box from '../../Box'
import EditorialPlugWrapper from './componentParts/EditorialPlugWrapper'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import PlugLink from '../../Plug/PlugLink/PlugLink'
import PlugPlayPauseProgressStaticButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressStaticButton'

const noop = () => {}

const EditorialPlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      tagline,
      title,
      description,
      type,
      seriesId,
      episodeId,
      seasonId,
      channelId,
      startTime,
      showPlay,
      children,
      accessibilityLabel,
      onClick = noop,
      onPlayClick = noop,
      ...props
    },
    ref
  ) => {
    const { ariaHiddenMaybe, ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)
    return (
      <EditorialPlugWrapper showPlay={showPlay} {...props} ref={ref}>
        <Box position="relative" className="editorial-image-wrapper">
          <PlugLink type={type} tabIndex="-1" aria-hidden="true" onClick={onClick} delay={1} to={linkUrl}>
            <PlugImage ratio={16 / 9} src={imageUrl} />
          </PlugLink>
        </Box>
        <EditorialTextWrapper type={type} as={PlugLink} onClick={onClick} delay={1} to={linkUrl} {...ariaLabelMaybe}>
          {tagline && (
            <PlugTagline {...ariaHiddenMaybe} lines={1}>
              {tagline}
            </PlugTagline>
          )}
          <PlugTitle {...ariaHiddenMaybe}>{title}</PlugTitle>
          <PlugBody {...ariaHiddenMaybe} lines={3}>
            {description}
          </PlugBody>
        </EditorialTextWrapper>
        {showPlay && (
          <Box pos="absolute" bottom="0">
            <PlugPlayPauseProgressStaticButton
              accessibilityLabel={accessibilityLabel}
              type={type}
              episodeId={episodeId}
              seriesId={seriesId}
              channelId={channelId}
              seasonId={seasonId}
              startTime={startTime}
              linkUrl={linkUrl}
              w="44px"
              h="44px"
              onPlayClick={onPlayClick}
            />
          </Box>
        )}
        {children}
      </EditorialPlugWrapper>
    )
  }
)

EditorialPlug.propTypes = {
  title: PropTypes.string,
  tagline: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  type: PropTypes.string,
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  startTime: PropTypes.string,
  showPlay: PropTypes.bool,
  accessibilityLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onPlayClick: PropTypes.func
}

EditorialPlug.displayName = 'EditorialPlug'

export default EditorialPlug
