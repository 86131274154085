import React from 'react'
import Box from '../../Box'
import AspectRatioBox from '../../AspectRatioBox'
import LoadingPlaceholder from '../../LoadingPlaceholder/LoadingPlaceholder'

const SquareLogoLoadingPlaceholder = props => {
  return (
    <Box>
      <AspectRatioBox ratio={1 / 1} {...props}>
        <LoadingPlaceholder w="full" h="full" />
      </AspectRatioBox>
    </Box>
  )
}

SquareLogoLoadingPlaceholder.propTypes = {}

export default SquareLogoLoadingPlaceholder
