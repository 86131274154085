import { durationToSeconds } from '../../../common/parseIsoDuration'
import logger from '../../../logger'
import toUrlTimestamp from '../../../RadioUI/Share/helpers/toUrlTimestamp'
import toTime from '../../../RadioUI/Share/helpers/toTime'
import {
  CHANNEL,
  EPISODE,
  LINK,
  PAGE,
  PODCAST,
  PODCAST_EPISODE,
  PODCAST_SEASON,
  SERIES,
  STANDALONE_PROGRAM
} from './plugTypes'

// TODO: Change podcast url-generating if whe get podcastId and podcastEpisodeId from the api

const generateUrl = plug => {
  switch (plug.type) {
    case PODCAST_EPISODE: {
      const maybeTimestamp = plug.podcastEpisode.startTime
        ? `#t=${toUrlTimestamp(toTime(durationToSeconds(plug.podcastEpisode.startTime)))}`
        : ''
      return `/podkast/${plug.podcastEpisode.podcastId}/${plug.podcastEpisode.episodeId}${maybeTimestamp}`
    }
    case PODCAST: {
      return `/podkast/${plug.podcast.podcastId}`
    }
    case SERIES: {
      return `/serie/${plug.series.seriesId}`
    }
    case EPISODE: {
      const maybeTimestamp = plug.episode.startTime
        ? `#t=${toUrlTimestamp(toTime(durationToSeconds(plug.episode.startTime)))}`
        : ''
      return `/serie/${plug.episode.seriesId}/${plug.episode.programId}${maybeTimestamp}`
    }
    case STANDALONE_PROGRAM: {
      const maybeTimestamp = plug.standaloneProgram.startTime
        ? `#t=${toUrlTimestamp(toTime(durationToSeconds(plug.standaloneProgram.startTime)))}`
        : ''
      return `/program/${plug.standaloneProgram.programId}${maybeTimestamp}`
    }
    case CHANNEL: {
      const channelId = plug.channel.channelId
      return `/direkte/${channelId}`
    }
    case PODCAST_SEASON: {
      return `/podkast/${plug.podcastSeason.podcastId}/sesong/${plug.podcastSeason.seasonId}`
    }
    case LINK: {
      return plug.link._links.externalUrl.href
    }
    case PAGE: {
      return `/kategori/${plug.page.pageId}`
    }
    default: {
      logger.warn('Missing url map for: ', plug)
    }
  }
}

export default generateUrl
