import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'
import PlugImage from '../../Plug/PlugImage'
import PlugPlayPauseProgressButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressButton'
import { CHANNEL, EPISODE, PODCAST_EPISODE, STANDALONE_PROGRAM } from '../../../pages/FrontPage/helpers/plugTypes'
import LandscapeLogoPlugWrapper from './componentParts/LandscapeLogoPlugWrapper'
import { css } from '@emotion/react'
import LandscapeTextWrapper from '../LandscapePlug/componentParts/LandscapeTextWrapper'
import PlugBody from '../../Plug/PlugBody'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import StartFromButton from '../../Plug/StartFromButton/StartFromButton'
import PlugLink from '../../Plug/PlugLink/PlugLink'

const noop = () => {}

const LandscapeLogoPlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      srcSet,
      description,
      type,
      title,
      episodeId,
      seriesId,
      channelId,
      startTime,
      accessibilityLabel,
      children,
      onClick = noop,
      onPlayClick = noop,
      ...props
    },
    ref
  ) => {
    const { ariaHiddenMaybe, ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)

    return (
      <LandscapeLogoPlugWrapper
        css={css`
          .landscape-text-body {
            opacity: 0;
          }
          &:hover {
            .landscape-text-body {
              opacity: 1;
            }
          }
        `}
        {...props}
        ref={ref}
      >
        <Box position="relative" className="landscape-image-wrapper">
          <PlugLink
            type={type}
            display="block"
            aria-label={accessibilityLabel}
            delay={1}
            to={linkUrl}
            onClick={onClick}
          >
            <PlugImage ratio={16 / 9} src={imageUrl} srcSet={srcSet} />
          </PlugLink>
          {[EPISODE, PODCAST_EPISODE, CHANNEL, STANDALONE_PROGRAM].includes(type) && !startTime && (
            <PlugPlayPauseProgressButton
              accessibilityLabel={accessibilityLabel}
              type={type}
              episodeId={episodeId}
              seriesId={seriesId}
              channelId={channelId}
              startTime={startTime}
              bottom="8px"
              right="8px"
              w={{ base: '32px', lg: '42px', xl: '64px' }}
              h={{ base: '32px', lg: '42px', xl: '64px' }}
              fontSize={{ base: '10px', lg: '12px', xl: '18px' }}
              onClick={onPlayClick}
            />
          )}
          {[PODCAST_EPISODE, EPISODE, STANDALONE_PROGRAM].includes(type) && startTime && (
            <StartFromButton
              type={type}
              accessibilityLabel={accessibilityLabel}
              episodeId={episodeId}
              seriesId={seriesId}
              startTime={startTime}
              position="absolute"
              right="8px"
              bottom="8px"
            />
          )}
        </Box>
        <LandscapeTextWrapper {...ariaLabelMaybe} type={type} as={PlugLink} delay={0} to={linkUrl} onClick={onClick}>
          <PlugBody className="landscape-text-body" {...ariaHiddenMaybe} lines={3}>
            {description}
          </PlugBody>
        </LandscapeTextWrapper>
        {children}
      </LandscapeLogoPlugWrapper>
    )
  }
)

LandscapeLogoPlug.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  srcSet: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  startTime: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onPlayClick: PropTypes.func
}

LandscapeLogoPlug.displayName = 'LandscapeLogoPlug'

export default LandscapeLogoPlug
