import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'
import PlugImage from '../../Plug/PlugImage'
import PlugTitle from '../../Plug/PlugTitle'
import LandscapeTextWrapper from './componentParts/LandscapeTextWrapper'
import LandscapePlugWrapper from './componentParts/LandscapePlugWrapper'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import PlugBody from '../../Plug/PlugBody'
import { css } from '@emotion/react'
import PlugLink from '../../Plug/PlugLink/PlugLink'
import PlugPlayPauseProgressStaticButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressStaticButton'

const noop = () => {}

const LandscapePlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      srcSet,
      title,
      description,
      type,
      episodeId,
      seriesId,
      seasonId,
      channelId,
      startTime,
      accessibilityLabel,
      showPlay,
      children,
      onClick = noop,
      onPlayClick = noop,
      ...props
    },
    ref
  ) => {
    const { ariaHiddenMaybe, ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)
    return (
      <LandscapePlugWrapper
        showPlay={showPlay}
        css={css`
          .landscape-text-body {
            opacity: 0;
          }
          &:hover {
            .landscape-text-body {
              opacity: 1;
            }
          }
        `}
        {...props}
        ref={ref}
      >
        <Box position="relative" className="landscape-image-wrapper">
          <PlugLink
            type={type}
            tabIndex="-1"
            aria-hidden="true"
            display="block"
            delay={1}
            to={linkUrl}
            onClick={onClick}
          >
            <PlugImage ratio={16 / 9} src={imageUrl} srcSet={srcSet} />
          </PlugLink>
        </Box>
        <LandscapeTextWrapper {...ariaLabelMaybe} type={type} as={PlugLink} delay={0} to={linkUrl} onClick={onClick}>
          <PlugTitle {...ariaHiddenMaybe} mb={3}>
            {title}
          </PlugTitle>
          <PlugBody className="landscape-text-body" {...ariaHiddenMaybe} lines={3}>
            {description}
          </PlugBody>
        </LandscapeTextWrapper>
        {showPlay && (
          <Box pos="absolute" bottom="0">
            <PlugPlayPauseProgressStaticButton
              accessibilityLabel={accessibilityLabel}
              type={type}
              episodeId={episodeId}
              seriesId={seriesId}
              channelId={channelId}
              seasonId={seasonId}
              startTime={startTime}
              linkUrl={linkUrl}
              w="44px"
              h="44px"
              onPlayClick={onPlayClick}
            />
          </Box>
        )}
        {children}
      </LandscapePlugWrapper>
    )
  }
)

LandscapePlug.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  srcSet: PropTypes.string,
  type: PropTypes.string,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  startTime: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  showPlay: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onPlayClick: PropTypes.func
}

LandscapePlug.displayName = 'LandscapePlug'

export default LandscapePlug
