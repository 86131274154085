import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'
import { css } from '@emotion/react'
import Scroller from '../../Scroller'
import MultiHeroPlug from '../../Plugs/MultiHero/MultiHeroPlug'
import PseudoBox from '../../PseudoBox'
import Image from '../../Image'
import Text from '../../Text'
import Box from '../../Box'
import extractMultiHeroImageUrl from '../../../pages/FrontPage/helpers/extractMultiHeroImageUrl'
import extractImageUrl from '../../../pages/FrontPage/helpers/extractImageUrl'
import extractProgramTitles from '../../../pages/FrontPage/helpers/exractTitles'
import createUrl from '../../../pages/FrontPage/helpers/createUrl'
import remapIds from '../../../pages/FrontPage/helpers/remapIds'
import HeadingReadOnly from '../../../components/HeadingReaderOnly'
import breakpoints from '../../theme/breakpoints'
import MultiHeroLoadingPlaceholder from './MultiHeroLoadingPlaceholder'
import { useColors } from '../../theme/Color'
import PlugLink from '../../Plug/PlugLink/PlugLink'
import PlugPlayPauseProgressStaticButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressStaticButton'
import {
  withClickTracking,
  withImpressionAndClickTracking,
  withOnPlayClickTracking
} from '../../../components/HOCs/withImpressionAndClickTracking'

const TrackedMultiHeroPlug = withImpressionAndClickTracking(MultiHeroPlug)
const TrackedMultiHeroPlugHeroPlayPauseButton = withOnPlayClickTracking(PlugPlayPauseProgressStaticButton)
const TrackedPlugLink = withClickTracking(PlugLink)

const MultiHeroStyle = colors => css`
  &::before {
    content: '';
    display: block;
    height: 63vmin;
    max-height: 63vmin;
  }
  @media (min-width: ${breakpoints.lg}) {
    &::before {
      height: 70vh;
      max-height: 70vh;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(90deg, ${colors.main.medium} 0%, ${colors.main.medium}00 100%);
  }
  @media all and (max-width: 1023px) {
    &::after {
      background: linear-gradient(
        0deg,
        ${colors.main.medium} 0.12%,
        ${colors.main.medium}80 55.22%,
        ${colors.main.medium}29 74.45%,
        ${colors.main.medium}00 95.2%
      );
    }
  }
  @media all and (max-width: 1023px) and (min-width: 992px) {
    & {
      min-height: 450px;
    }
  }
`
const noop = () => {}

const MultiHeroSection = forwardRef(
  (
    {
      sectionId,
      sectionTitle,
      plugs,
      isMobile,
      indexOfSection,
      displayContract,
      pageId,
      recommendationId,
      onChangeQueue = noop,
      onShareContent = noop,
      onViewQueue = noop,
      onRemoveFromQueue = noop,
      onUnFavourite = noop,
      onFavourite = noop,
      onUnMarkCompleted = noop,
      onMarkCompleted = noop,
      onClickDuplicate = noop,
      onPlugClick = noop,
      onPlayClick = noop,
      onScrollNavigationClick = noop,
      onScrollChange = noop,
      onClickTitle = noop,
      onDialogOpen = noop
    },
    ref
  ) => {
    const [activeMultiHero, setActiveMultiHero] = useState(null)
    const scrollerRef = useRef()
    const colors = useColors()
    const handleMultiHeroHover = activePlug => {
      setActiveMultiHero(activePlug)
    }

    const debouncedChangeHandler = useMemo(
      () => _debounce(handleMultiHeroHover, typeof window !== 'undefined' && window.innerWidth < 1024 ? 250 : 300),
      []
    )

    const setActiveAfterScroll = () => {
      const children = scrollerRef.current.el.childNodes
      const plug = plugs.find((plug, i) => children[i].offsetLeft > scrollerRef.current.el.scrollLeft)
      debouncedChangeHandler.cancel()
      debouncedChangeHandler(plug || plugs[0])
    }

    useEffect(() => {
      return () => {
        debouncedChangeHandler.cancel()
      }
    }, [debouncedChangeHandler])

    useEffect(() => {
      if (!activeMultiHero) {
        setActiveMultiHero(plugs[0])
      }
    }, [plugs, activeMultiHero])

    if (!activeMultiHero) return <MultiHeroLoadingPlaceholder />
    return (
      <Box key={sectionId} className="multihero-section" as="section" w="full" pos="relative">
        <HeadingReadOnly heading="Anbefalt" type="h2" />
        <Box display="flex" flexDir="column" mb="2.5rem">
          <TrackedPlugLink
            type={activeMultiHero.type}
            className="multihero-section-header"
            to={createUrl(activeMultiHero)}
            onClick={() => onPlugClick(activeMultiHero)}
            pos="relative"
            d="flex"
            flexDir="column"
            aria-hidden="true"
            mt={{ base: 0, lg: 'calc(-1*154px + 20px)' }}
            mb={{ base: '7.3rem', sm: '5.3rem', md: '3.3rem', lg: 'calc(-1*(4.3rem + ((82vw - (5*1.5rem))/6)/2))' }}
            zIndex={0}
            tabIndex="-1"
            sectionId={sectionId}
            sectionTitle={sectionTitle}
            indexOfSection={indexOfSection}
            displayContract={displayContract}
            imageId={activeMultiHero.image.id}
            pageId={pageId}
            recommendationId={recommendationId}
            contentId={activeMultiHero.analytics.contentId}
            contentSource={activeMultiHero.analytics.contentSource}
            contentTitle={activeMultiHero.analytics.title}
          >
            <PseudoBox
              className="multihero-section-backdrop"
              mx={{ base: 0, lg: '20px' }}
              maxH={{ base: '50vmin', lg: '50vw' }}
              minH={{ base: null, lg: '600px' }}
              pos="relative"
              css={MultiHeroStyle(colors)}
              zIndex={{ lg: -1 }}
            >
              {plugs.map(plug => (
                <div key={plug.id}>
                  <Image
                    pos="absolute"
                    top="0"
                    left="0"
                    w="100%"
                    height="100%"
                    objectFit="cover"
                    objectPosition="50% 10%"
                    className="multihero-backdrop-image"
                    opacity={activeMultiHero.id === plug.id ? 1 : 0}
                    transition="opacity .5s cubic-bezier(.12,0,0,1)"
                    src={extractMultiHeroImageUrl(plug, 1920)}
                    borderRadius={{ base: 0, lg: '6px' }}
                  />
                  <PseudoBox
                    d="flex"
                    pos="absolute"
                    left={{ base: '5%', lg: 'calc(9vw - 20px)' }}
                    right={{ base: '5%', lg: 'calc(9vw - 20px)' }}
                    m="0"
                    justifyContent="end"
                    flexDir="column"
                    bottom={{
                      base: '-6.6rem',
                      sm: '-4.6rem',
                      md: '-2.6rem',
                      lg: 'calc((6.3rem + ((82vw - (5*1.5rem))/6)/2))'
                    }}
                    zIndex={1}
                    transform={activeMultiHero.id === plug.id ? 'translateY(0px)' : 'translateY(30px)'}
                    opacity={activeMultiHero.id === plug.id ? 1 : 0}
                    transition="opacity, transform .5s cubic-bezier(.12,0,0,1)"
                  >
                    <Text
                      as="h3"
                      variant={isMobile ? 'title2' : 'title1'}
                      mb={2}
                      color={colors.main.light}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {plug.tagline}
                    </Text>
                    {plug.title && (
                      <Text variant={isMobile ? 'headline' : 'title2'} mb={2}>
                        {plug.title}
                      </Text>
                    )}

                    <Text
                      variant="body"
                      opacity={{ base: '0.7', lg: '1' }}
                      w={{ base: '100%', md: 300, lg: 400 }}
                      css={css`
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3; /* number of lines to show */
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                      `}
                    >
                      {plug.description}
                    </Text>
                  </PseudoBox>
                </div>
              ))}
            </PseudoBox>
          </TrackedPlugLink>
          <Box mx={{ base: '5%', lg: '9%' }} mb={{ base: '0.6rem', lg: '2.6rem' }}>
            <TrackedMultiHeroPlugHeroPlayPauseButton
              accessibilityLabel={activeMultiHero.accessibilityLabel}
              type={activeMultiHero.type}
              episodeId={remapIds(activeMultiHero).episodeId}
              channelId={remapIds(activeMultiHero).channelId}
              seriesId={remapIds(activeMultiHero).seriesId}
              seasonId={remapIds(activeMultiHero).seasonId}
              contentId={activeMultiHero.analytics?.contentId}
              startTime={activeMultiHero[activeMultiHero.type].startTime}
              w={{ base: '48px', lg: '56px' }}
              h={{ base: '48px', lg: '56px' }}
              onPlayClick={() => onPlayClick(activeMultiHero)}
              pageId={pageId}
            />
          </Box>
          <PseudoBox className="multihero-plugs" width="100%" onMouseLeave={() => debouncedChangeHandler.cancel()}>
            <Scroller
              ref={scrollerRef}
              onScrollChange={event => {
                if (isMobile) {
                  setActiveAfterScroll()
                }
                if (event.target.scrollLeft > 0) {
                  onScrollChange()
                }
              }}
              onScrollNavigationClick={onScrollNavigationClick}
              css={css`
                .core-scroll {
                  scroll-snap-type: x mandatory;
                  scroll-padding: 5%;
                }

                @media all and (min-width: ${breakpoints.lg}) {
                  .core-scroll {
                    scroll-padding: 9%;
                    scroll-snap-type: unset;
                  }
                }
              `}
            >
              {plugs.map((plug, i) => {
                const { episodeId, channelId, seriesId } = remapIds(plug)
                const { channelTitle, seriesTitle, episodeTitle } = extractProgramTitles(plug)
                const linkUrl = createUrl(plug)
                return (
                  <TrackedMultiHeroPlug
                    key={plug.id}
                    linkUrl={linkUrl}
                    imageUrl={extractImageUrl(plug, 177)}
                    type={plug.type}
                    data-test="multi-hero-plug"
                    accessibilityLabel={plug.accessibilityLabel}
                    isActive={activeMultiHero && activeMultiHero.id === plug.id}
                    onClick={() => onPlugClick(plug)}
                    onPlayClick={() => onPlayClick(plug)}
                    onSelectAsActive={() => {
                      debouncedChangeHandler.cancel()
                      handleMultiHeroHover(plug)
                    }}
                    onHover={() => debouncedChangeHandler(plug)}
                    isMobile={isMobile}
                    episodeId={episodeId}
                    seriesId={seriesId}
                    channelId={channelId}
                    channelTitle={channelTitle}
                    episodeTitle={episodeTitle}
                    seriesTitle={seriesTitle}
                    sectionId={sectionId}
                    sectionTitle={sectionTitle}
                    indexOfSection={indexOfSection}
                    displayContract={displayContract}
                    indexInSection={i + 1}
                    imageId={plug.image.id}
                    pageId={pageId}
                    recommendationId={recommendationId}
                    contentTitle={plug.analytics.title}
                    contentId={plug.analytics.contentId}
                    contentSource={plug.analytics.contentSource}
                    contentType={plug.analytics.contentType}
                    startTime={plug.channel?.startDateTime}
                    onChangeQueue={(action, type) => onChangeQueue(episodeId, seriesId, action, type)}
                    onShareContent={() => onShareContent(episodeId, seriesId)}
                    onViewQueue={() => onViewQueue(episodeId, seriesId)}
                    onRemoveFromQueue={() => onRemoveFromQueue(episodeId, seriesId)}
                    onUnFavourite={() => onUnFavourite(episodeId, seriesId)}
                    onFavourite={() => onFavourite(episodeId, seriesId)}
                    onUnMarkCompleted={() => onUnMarkCompleted(episodeId, seriesId)}
                    onMarkCompleted={() => onMarkCompleted(episodeId, seriesId)}
                    onClickDuplicate={() => onClickDuplicate(episodeId, seriesId)}
                    onClickTitle={() => onClickTitle(episodeId, seriesId)}
                    onDialogOpen={onDialogOpen}
                  />
                )
              })}
            </Scroller>
          </PseudoBox>
        </Box>
      </Box>
    )
  }
)

MultiHeroSection.propTypes = {
  sectionId: PropTypes.string,
  sectionTitle: PropTypes.string,
  plugs: PropTypes.array,
  category: PropTypes.string,
  variant: PropTypes.string,
  isMobile: PropTypes.bool,
  indexOfSection: PropTypes.number,
  displayContract: PropTypes.string,
  pageId: PropTypes.string,
  recommendationId: PropTypes.string,
  onChangeQueue: PropTypes.func,
  onShareContent: PropTypes.func,
  onViewQueue: PropTypes.func,
  onRemoveFromQueue: PropTypes.func,
  onUnFavourite: PropTypes.func,
  onFavourite: PropTypes.func,
  onUnMarkCompleted: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  onClickDuplicate: PropTypes.func,
  onPlugClick: PropTypes.func,
  onPlayClick: PropTypes.func,
  onScrollNavigationClick: PropTypes.func,
  onScrollChange: PropTypes.func,
  onClickTitle: PropTypes.func,
  onDialogOpen: PropTypes.func
}

MultiHeroSection.displayName = 'MultiHeroSection'

export default MultiHeroSection
