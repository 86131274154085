import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PlayPauseProgressButton from '../../PlayProgressButton/PlayProgressButton'
import { CHANNEL, PODCAST_EPISODE } from '../../../pages/FrontPage/helpers/plugTypes'
import { focusVisibleStyles } from '../../Plugs/styles/focusStyles'
import { differenceInSeconds } from 'date-fns'
import { useColors } from '../../theme/Color'
import { useCurrentlyPlayingId } from '../../../components/StickyPlayer/context/PlayerMetadataContext'
import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'
import { useIsPlayerPlaying } from '../../../components/StickyPlayer/hooks/useAudioAndVideoPlayerElementState'
import { useAudioOrVideoPlayerElement } from '../../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../../components/Queues/UpNext/UpNextContext'

const noop = () => {}

const toMediaType = type => {
  switch (type) {
    case PODCAST_EPISODE:
      return MEDIA_TYPES.PODCAST
    case CHANNEL:
      return MEDIA_TYPES.CHANNEL
    default:
      return MEDIA_TYPES.PROGRAM
  }
}

const PlugPlayPauseProgressButton = ({
  type,
  episodeId,
  channelId,
  seriesId,
  accessibilityLabel,
  startTime,
  contentContext,
  favouriteLevel,
  onClick = noop,
  ...restProps
}) => {
  const colors = useColors()
  const isPlayerPlaying = useIsPlayerPlaying()
  const currentlyPlayingId = useCurrentlyPlayingId()
  const { loadAndStartOrTogglePlayPause } = useAudioOrVideoPlayerElement()
  const isMediaItemInPlayer = currentlyPlayingId === episodeId || currentlyPlayingId === channelId
  const isPlaying = isMediaItemInPlayer && isPlayerPlaying
  const [diffSec, setDiffSec] = useState(differenceInSeconds(startTime, new Date()))
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  useEffect(() => {
    const interval = setInterval(() => {
      setDiffSec(differenceInSeconds(startTime, new Date()))
    }, 1000)

    return () => clearInterval(interval)
  }, [startTime])

  const handleOnClick = useCallback(
    event => {
      setUpNextContentContext(contentContext)
      setUpNextFavoriteLevel(favouriteLevel)
      loadAndStartOrTogglePlayPause({ mediaType: toMediaType(type), episodeId, channelId, seriesId })
      onClick(event)
    },
    [
      channelId,
      episodeId,
      loadAndStartOrTogglePlayPause,
      onClick,
      seriesId,
      type,
      setUpNextContentContext,
      contentContext,
      setUpNextFavoriteLevel,
      favouriteLevel
    ]
  )

  const ariaLabel = isPlaying
    ? `Pause ${accessibilityLabel || ''}`.trim()
    : `Spill av ${accessibilityLabel || ''}`.trim()

  if (diffSec > 0) {
    return null
  }

  return (
    <PlayPauseProgressButton
      className="PlayPauseProgressButton"
      bg={type === CHANNEL ? colors.live.mediumLight10 : colors.main.mediumLight10}
      pos="absolute"
      _hover={{ bg: type === CHANNEL ? colors.live.mediumLight20 : colors.main.mediumLight20 }}
      _active={{ bg: type === CHANNEL ? colors.live.mediumLight20 : colors.main.mediumLight20 }}
      _focus={{ opacity: 1 }}
      onClick={handleOnClick}
      isPlaying={isPlaying}
      aria-label={ariaLabel}
      css={focusVisibleStyles}
      {...restProps}
    />
  )
}

PlugPlayPauseProgressButton.propTypes = {
  type: PropTypes.string,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  onClick: PropTypes.func,
  startTime: PropTypes.string,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default PlugPlayPauseProgressButton
