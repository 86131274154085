import React from 'react'
import PropTypes from 'prop-types'
import AspectRatioBox from '../../AspectRatioBox'
import Box from '../../Box'
import PseudoBox from '../../PseudoBox'
import { useInView } from 'react-intersection-observer'

const PlugImage = ({ ratio = 16 / 9, src, alt = '', children, ...props }) => {
  const [inViewRef, inView] = useInView()
  return (
    <AspectRatioBox ratio={ratio} className="plug-image" ref={inViewRef}>
      <Box
        overflow="hidden"
        background="linear-gradient(225deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        borderRadius="md"
      >
        {inView ? (
          <PseudoBox as="img" w="100%" h="auto" borderRadius="md" src={src} alt={alt} {...props} />
        ) : (
          <PseudoBox w="100%" h="auto" borderRadius="md" {...props} />
        )}
        {children}
      </Box>
    </AspectRatioBox>
  )
}

PlugImage.propTypes = {
  alt: PropTypes.string,
  ratio: PropTypes.number,
  src: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default PlugImage
