import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Text from '../../Text'

export const lineHeight = { base: '20px', md: '24px' }

const PlugTitle = forwardRef(({ children, ...props }, ref) => {
  return (
    <Text
      ref={ref}
      as="h3"
      className="PlugTitle"
      variant="subhead"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      mb={1}
      {...props}
    >
      {children}
    </Text>
  )
})

PlugTitle.propTypes = {
  children: PropTypes.node
}

PlugTitle.displayName = 'PlugTitle'

export default PlugTitle
