import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'
import PlugImage from '../../Plug/PlugImage'
import Text from '../../Text'
import PseudoBox from '../../PseudoBox'
import { hoverMovementStylesFromLg } from '../styles/hoverMovementStyles'
import { initHoverColorStyles } from '../styles/hoverColorStyles'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import { focusVisibleStyles } from '../styles/focusStyles'
import { plugButtonsHoverStyles } from '../../Plug/PlugPlayPauseProgressButton/plugButtonStyles'
import { useColors } from '../../theme/Color'
import PlugLink from '../../Plug/PlugLink/PlugLink'
import PlugPlayPauseProgressStaticButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressStaticButton'

const noop = () => {}

const SquarePlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      title,
      type,
      episodeId,
      seriesId,
      channelId,
      seasonId,
      startTime,
      accessibilityLabel,
      showPlay,
      onClick = noop,
      children,
      ...props
    },
    ref
  ) => {
    const { ariaLabelMaybe, ariaHiddenMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)
    const hoverColorStyles = initHoverColorStyles(useColors().main, '.SquarePlugTitle')
    return (
      <PseudoBox
        position="relative"
        flex="0 0 auto"
        paddingBottom={showPlay ? 'calc(44px + 8px)' : 0}
        css={[hoverMovementStylesFromLg, hoverColorStyles, focusVisibleStyles, !showPlay && plugButtonsHoverStyles]}
        ref={ref}
        {...props}
      >
        <PlugLink
          type={type}
          display="block"
          to={linkUrl}
          {...ariaLabelMaybe}
          onClick={onClick}
          delay={1}
          position="relative"
        >
          <PlugImage ratio={1} src={imageUrl} />
          <Box pt={2} {...ariaHiddenMaybe}>
            <Text
              as="h3"
              variant="subhead"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              mb={showPlay ? '10px' : '0px'}
            >
              {title}
            </Text>
          </Box>
        </PlugLink>
        {showPlay && (
          <Box pos="absolute" bottom="0">
            <PlugPlayPauseProgressStaticButton
              accessibilityLabel={accessibilityLabel}
              type={type}
              episodeId={episodeId}
              seriesId={seriesId}
              channelId={channelId}
              seasonId={seasonId}
              startTime={startTime}
              linkUrl={linkUrl}
              w="40px"
              h="40px"
              onPlayClick={onClick}
            />
          </Box>
        )}
        {children}
      </PseudoBox>
    )
  }
)

SquarePlug.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  children: PropTypes.node,
  showPlay: PropTypes.bool,
  onClick: PropTypes.func,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  startTime: PropTypes.string
}

SquarePlug.displayName = 'SquarePlug'

export default SquarePlug
