import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'
import PlugImage from '../../Plug/PlugImage'
import { hoverMovementStylesFromLg } from '../styles/hoverMovementStyles'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import { focusVisibleStyles, focusWithinStyles } from '../styles/focusStyles'
import { plugButtonsHoverStyles } from '../../Plug/PlugPlayPauseProgressButton/plugButtonStyles'
import PlugLink from '../../Plug/PlugLink/PlugLink'

const noop = () => {}

const SquareLogoPlug = forwardRef(
  ({ linkUrl, imageUrl, type, title, accessibilityLabel, onClick = noop, children, ...props }, ref) => {
    const { ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)
    return (
      <Box
        position="relative"
        flex="0 0 auto"
        css={[hoverMovementStylesFromLg, focusVisibleStyles, focusWithinStyles, plugButtonsHoverStyles]}
        ref={ref}
        {...props}
      >
        <PlugLink
          type={type}
          display="block"
          to={linkUrl}
          delay={1}
          onClick={onClick}
          {...ariaLabelMaybe}
          position="relative"
        >
          <PlugImage ratio={1} src={imageUrl} />
        </PlugLink>
        {children}
      </Box>
    )
  }
)

SquareLogoPlug.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
}

SquareLogoPlug.displayName = 'SquareLogoPlug'

export default SquareLogoPlug
