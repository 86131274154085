import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { CHANNEL, EPISODE, PODCAST_EPISODE, STANDALONE_PROGRAM } from '../../../pages/FrontPage/helpers/plugTypes'
import CustomButton from '../../CustomButton'
import Text from '../../Text'
import Icon from '../../Icon'
import { durationToSeconds } from '../../../common/parseIsoDuration'
import { durationToNorwegianMinutesPresicion } from '../../../common/formatDuration'
import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../../components/Queues/UpNext/UpNextContext'

const noop = () => {}

const toMediaType = type => {
  switch (type) {
    case PODCAST_EPISODE:
      return MEDIA_TYPES.PODCAST
    case CHANNEL:
      return MEDIA_TYPES.CHANNEL
    default:
      return MEDIA_TYPES.PROGRAM
  }
}

const StartFromButton = ({
  startTime,
  type,
  episodeId,
  channelId,
  seriesId,
  accessibilityLabel,
  onClick = noop,
  contentContext,
  favouriteLevel,
  ...restProps
}) => {
  const { loadAndStartOrPlayFrom } = useAudioOrVideoPlayerElement()
  const startTimeSeconds = durationToSeconds(startTime)
  const formattedSeconds = durationToNorwegianMinutesPresicion(startTime)
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  const handleClick = useCallback(
    event => {
      setUpNextContentContext(contentContext)
      setUpNextFavoriteLevel(favouriteLevel)
      loadAndStartOrPlayFrom({ mediaType: toMediaType(type), seriesId, episodeId, channelId, seekTo: startTimeSeconds })
      onClick(event)
    },
    [
      channelId,
      episodeId,
      loadAndStartOrPlayFrom,
      onClick,
      seriesId,
      startTimeSeconds,
      type,
      setUpNextContentContext,
      contentContext,
      setUpNextFavoriteLevel,
      favouriteLevel
    ]
  )

  return (
    <CustomButton
      className="StartFromButton"
      onClick={handleClick}
      display="flex"
      alignItems="center"
      height="8"
      rounded="full"
      px="12px"
      fontSize="0.875rem"
      {...restProps}
    >
      <Icon iconId="nrk-media-play" size="xs" /> <Text pl="12px">Hør fra {formattedSeconds}</Text>
    </CustomButton>
  )
}

StartFromButton.propTypes = {
  startTime: PropTypes.string,
  type: PropTypes.oneOf([CHANNEL, EPISODE, PODCAST_EPISODE, STANDALONE_PROGRAM]),
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  onClick: PropTypes.func,
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default StartFromButton
