import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../../Box'
import { hoverMovementStylesFromLg } from '../../styles/hoverMovementStyles'
import hoverColorStyles from '../../styles/hoverColorStyles'
import { focusVisibleStyles, focusWithinStyles } from '../../styles/focusStyles'
import { useColors } from '../../../theme/Color'
import { plugButtonsHoverStyles } from '../../../Plug/PlugPlayPauseProgressButton/plugButtonStyles'

const EditorialPlugWrapper = forwardRef(({ showPlay, children, ...props }, ref) => {
  return (
    <Box
      position="relative"
      flex="0 0 auto"
      d="flex"
      flexDir="column"
      paddingBottom={showPlay ? 'calc(44px + 8px)' : 0}
      css={[
        hoverMovementStylesFromLg,
        hoverColorStyles(useColors().main),
        focusWithinStyles,
        focusVisibleStyles,
        !showPlay && plugButtonsHoverStyles
      ]}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  )
})

EditorialPlugWrapper.propTypes = {
  showPlay: PropTypes.bool,
  children: PropTypes.node
}

EditorialPlugWrapper.displayName = 'EditorialPlugWrapper'

export default EditorialPlugWrapper
