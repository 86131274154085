import React from 'react'
import AspectRatioBox from '../../AspectRatioBox'
import Box from '../../Box'
import Flex from '../../Flex'
import PseudoBox from '../../PseudoBox'
import LoadingPlaceholder from '../../LoadingPlaceholder/LoadingPlaceholder'

const HeroLoadingPlaceholder = props => {
  return (
    <PseudoBox d="flex" flexDirection={{ base: 'column', lg: 'row' }} mb={6} borderRadius="6px" overflow="hidden">
      <AspectRatioBox ratio={16 / 9} w={{ base: '100%', lg: '62%' }} flex="0 0 auto">
        <LoadingPlaceholder h="full" w="full" />
      </AspectRatioBox>
      <Flex align="center" p={{ base: 6, lg: 12 }} w={{ base: '100%', lg: '38%' }} flex="0 0 auto">
        <Box w="full">
          <LoadingPlaceholder h="1.5rem" mb={4} />
          <LoadingPlaceholder h="25px" />
        </Box>
      </Flex>
    </PseudoBox>
  )
}

export default HeroLoadingPlaceholder
