import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../Text'

const PlugTagline = ({ children, ...props }) => {
  return (
    <Text
      as="h4"
      className="PlugTagline"
      variant="caption1"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      mb={1}
      {...props}
    >
      {children}
    </Text>
  )
}

PlugTagline.propTypes = {
  children: PropTypes.node
}

export default PlugTagline
