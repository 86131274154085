const extractMultiHeroImageUrl = (plug, minWidth) => {
  const { webImages = [] } = plug.backdropImage || plug.image
  if (webImages.length > 1) {
    const foundImage = webImages.find(({ width }) => width >= minWidth)
    return foundImage?.uri
  } else {
    return webImages[0]?.uri
  }
}

export default extractMultiHeroImageUrl
