import { matchPath } from 'react-router'
import logger from '../../../logger'
import {
  CHANNEL,
  EPISODE,
  PODCAST,
  PODCAST_EPISODE,
  SERIES,
  STANDALONE_PROGRAM,
  PODCAST_SEASON,
  LINK,
  PAGE
} from './plugTypes'

const remapIds = plug => {
  switch (plug.type) {
    case PODCAST_EPISODE: {
      const { params } = matchPath(plug.podcastEpisode._links.podcastEpisode.href, {
        path: '/radio/catalog/podcast/:seriesId/episodes/:episodeId'
      })

      return params
    }
    case PODCAST: {
      const { params } = matchPath(plug.podcast._links.podcast.href, {
        path: '/radio/catalog/podcast/:seriesId'
      })
      return params
    }
    case SERIES: {
      const { seriesId, programId: episodeId } = plug.series
      return { seriesId, episodeId }
    }
    case EPISODE: {
      const { seriesId, programId: episodeId } = plug.episode
      return { seriesId, episodeId }
    }
    case STANDALONE_PROGRAM: {
      const { programId: episodeId } = plug.standaloneProgram
      return { episodeId }
    }
    case CHANNEL: {
      const { channelId } = plug.channel
      return { channelId }
    }
    case PODCAST_SEASON: {
      const { podcastId: seriesId, seasonId } = plug.podcastSeason
      return { seriesId, seasonId }
    }
    case LINK: {
      return { linkId: plug.link._links.externalUrl.href }
    }
    case PAGE: {
      return { pageId: plug.page.pageId }
    }
    default: {
      logger.warn('Missing id-map for: ', plug)
      return {}
    }
  }
}

export default remapIds
