import { useMemo } from 'react'

const EMPTY_OBJECT = {}

/**
 *
 * @typedef {Object} AriaAttributeObjects
 * @property {Object} ariaLabelMaybe
 * @property {Object} ariaHiddenMaybe
 */

/**
 *
 * @param {string} accessibilityLabel
 * @returns {AriaAttributeObjects}
 */
const useAccessibilityLabelToAttributes = accessibilityLabel => {
  const ariaAttributes = useMemo(() => {
    const ariaLabelMaybe = accessibilityLabel ? { 'aria-label': accessibilityLabel } : EMPTY_OBJECT
    const ariaHiddenMaybe = accessibilityLabel ? { 'aria-hidden': true } : EMPTY_OBJECT

    return { ariaLabelMaybe, ariaHiddenMaybe }
  }, [accessibilityLabel])

  return ariaAttributes
}

export default useAccessibilityLabelToAttributes
