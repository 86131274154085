import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../../Box'
import { initHoverColorStyles } from '../../styles/hoverColorStyles'
import { hoverMovementStylesFromLg } from '../../styles/hoverMovementStyles'
import { focusVisibleStyles, focusWithinStyles } from '../../styles/focusStyles'
import { useColors } from '../../../theme/Color'
import { plugButtonsHoverStyles } from '../../../Plug/PlugPlayPauseProgressButton/plugButtonStyles'

const SimplePlugWrapper = forwardRef(({ showPlay, children, ...props }, ref) => {
  const hoverColorStyles = initHoverColorStyles(useColors().main, '.PlugTitle')
  return (
    <Box
      className="SimplePlugWrapper"
      position="relative"
      flex="0 0 auto"
      paddingBottom={showPlay ? 'calc(44px + 8px)' : 0}
      ref={ref}
      css={[
        hoverMovementStylesFromLg,
        !showPlay && plugButtonsHoverStyles,
        hoverColorStyles,
        focusWithinStyles,
        focusVisibleStyles
      ]}
      {...props}
    >
      {children}
    </Box>
  )
})

SimplePlugWrapper.propTypes = {
  showPlay: PropTypes.bool,
  children: PropTypes.node
}
SimplePlugWrapper.displayName = 'SimplePlugWrapper'

export default SimplePlugWrapper
