import React from 'react'
import PropTypes from 'prop-types'
import AspectRatioBox from '../../AspectRatioBox'
import LoadingPlaceholder from '../../LoadingPlaceholder/LoadingPlaceholder'
import Box from '../../Box'
import { lineHeight } from '../../Plug/PlugTitle'

const SimpleLoadingPlaceholder = props => {
  const { w } = props
  return (
    <Box {...props}>
      <AspectRatioBox ratio={16 / 9} w={w}>
        <LoadingPlaceholder w="full" h="full" />
      </AspectRatioBox>
      <Box pt={4}>
        <LoadingPlaceholder as="h3" display="block" w="full" mb={3} h={lineHeight} />
      </Box>
    </Box>
  )
}

SimpleLoadingPlaceholder.propTypes = {
  w: PropTypes.any
}

export default SimpleLoadingPlaceholder
