import React from 'react'
import Box from '../../RadioUI/Box'
import PropTypes from 'prop-types'
import ContentWidth from '../../RadioUI/ContentWidth'
import Text from '../../RadioUI/Text'
import { Link, useHistory } from 'react-router-dom'
import { useColors } from '../../RadioUI/theme/Color'
import { linkStyle } from '../../RadioUI/theme/link'
import { trackSnowplowEvent, spCategory } from '../../clientMonitoring'

const noop = () => {}

const SectionWrapperSeeAll = ({ children, title, to, onClick = noop, ...restProps }) => {
  const history = useHistory()
  const colors = useColors()
  return (
    <Box as="section" w="full" mb="10" {...restProps}>
      {title && (
        <ContentWidth
          as={Link}
          title="Vis alle"
          onClick={event => {
            event.preventDefault()
            trackSnowplowEvent(spCategory.Frontpage, 'ShowAllButtonTapped')
            onClick(event)
            history.push(to)
            window.scrollTo({ top: 0 })
          }}
          display="flex"
          alignItem="center"
          py="2"
          to={to}
          css={linkStyle(colors.main)}
        >
          <Text as="h2" variant="headlineExtraBold" data-test="discover-section">
            {title}
          </Text>
          <Text
            className="SectionWrapperSeeAll__ShowAllButton"
            size="md"
            ml="auto"
            color={colors.main.light}
            aria-hidden="true"
          >
            Vis alle
          </Text>
        </ContentWidth>
      )}
      {children}
    </Box>
  )
}

SectionWrapperSeeAll.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
}
export default SectionWrapperSeeAll
