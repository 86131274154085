import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import AspectRatioBox from '../../AspectRatioBox'
import Box from '../../Box'
import Image from '../../Image'
import PseudoBox from '../../PseudoBox'
import Text from '../../Text'
import { initHoverMovementStylesFromLg } from '../styles/hoverMovementStyles'
import { focusVisibleStyles, focusWithinStyles } from '../styles/focusStyles'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import HeadingReadOnly from '../../../components/HeadingReaderOnly'
import { useColors } from '../../theme/Color'
import PlugLink from '../../Plug/PlugLink/PlugLink'
import { css } from '@emotion/react'
import PlugPlayPauseProgressStaticButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressStaticButton'

const noop = () => {}

const hoverStyles = initHoverMovementStylesFromLg('&')

const HeroPlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      tagline,
      title,
      type,
      episodeId,
      seriesId,
      seasonId,
      channelId,
      startTime,
      accessibilityLabel,
      children,
      onClick = noop,
      onPlayClick = noop,
      ...props
    },
    ref
  ) => {
    const { ariaHiddenMaybe, ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)
    const isMobile = typeof window !== 'undefined' ? window.innerWidth < 1024 : false

    return (
      <PseudoBox
        d="flex"
        flexDirection={{ base: 'column', lg: 'row' }}
        mb={6}
        borderRadius="6px"
        overflow="hidden"
        position="relative"
        css={[hoverStyles, focusWithinStyles, focusVisibleStyles]}
        ref={ref}
        {...props}
      >
        <HeadingReadOnly heading="Anbefalt" type="h2" />
        <Box position="relative" w={{ base: '100%', lg: '62%' }} flex="0 0 auto">
          <PlugLink type={type} onClick={onClick} to={linkUrl} delay={1} tabIndex="-1" {...ariaHiddenMaybe}>
            <AspectRatioBox ratio={16 / 9}>
              <Box overflow="hidden">
                <Image src={imageUrl} w="auto" maxW="initial" h="100%" />
              </Box>
            </AspectRatioBox>
          </PlugLink>
        </Box>
        <Box
          bg={useColors().main.dark}
          w={{ base: '100%', lg: '38%' }}
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          pos="relative"
        >
          <PlugLink
            type={type}
            display="flex"
            onClick={onClick}
            to={linkUrl}
            delay={1}
            flex="1 1 auto"
            p={{ base: 6, xl: 12 }}
            pt={{ base: 6, xl: '4rem' }}
            pb={{ base: 'calc(1.5rem + 48px + 1.5rem);', xl: 'calc(3rem + 56px + 1.5rem);' }}
            {...ariaLabelMaybe}
          >
            <Box {...ariaHiddenMaybe} w="100%" d="flex" flexDir="column" justifyContent="center">
              {tagline && (
                <Text as="h3" variant={isMobile ? 'title2' : 'title1'} mb={4} wordBreak="break-word">
                  {tagline}
                </Text>
              )}
              <Text
                variant={isMobile ? 'body' : 'preamble'}
                wordBreak="break-word"
                opacity="0.7"
                css={css`
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  line-clamp: 3;
                  -webkit-box-orient: vertical;
                  @media all and (min-width: 1280px) {
                    -webkit-line-clamp: 5;
                    line-clamp: 5;
                  }
                `}
              >
                {title}
              </Text>
            </Box>
          </PlugLink>
          <Box position="absolute" left={{ base: 6, xl: 12 }} bottom={{ base: 6, xl: 12 }}>
            <PlugPlayPauseProgressStaticButton
              accessibilityLabel={accessibilityLabel}
              type={type}
              episodeId={episodeId}
              channelId={channelId}
              seriesId={seriesId}
              seasonId={seasonId}
              startTime={startTime}
              linkUrl={linkUrl}
              w={{ base: '48px', lg: '56px' }}
              h={{ base: '48px', lg: '56px' }}
              onPlayClick={onPlayClick}
            />
          </Box>
        </Box>
        {children}
      </PseudoBox>
    )
  }
)

HeroPlug.propTypes = {
  linkUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  startTime: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  episodeId: PropTypes.string,
  seriesId: PropTypes.string,
  channelId: PropTypes.string,
  seasonId: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  onPlayClick: PropTypes.func
}

HeroPlug.displayName = 'HeroPlug'

export default HeroPlug
