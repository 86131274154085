import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../Text'
import { css } from '@emotion/react'

const PlugBody = ({ lines, children, ...props }) => {
  return (
    <Text
      as="span"
      variant="footnote"
      opacity="0.7"
      css={css`
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
        overflow: hidden;
      `}
      {...props}
    >
      {children}
    </Text>
  )
}

PlugBody.propTypes = {
  lines: PropTypes.number.isRequired,
  children: PropTypes.node
}

export default PlugBody
