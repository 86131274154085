import React from 'react'
import AspectRatioBox from '../../AspectRatioBox'
import PseudoBox from '../../PseudoBox'
import LoadingPlaceholder from '../../LoadingPlaceholder/LoadingPlaceholder'
import Scroller from '../../Scroller'
import SquareLogoLoadingPlaceholder from '../SquareLogoPlug/SquareLogoLoadingPlaceholder'
import { squarePlugWidthMedium } from '../styles/plugWidths'

const MultiHeroLoadingPlaceholder = props => {
  return (
    <PseudoBox w="100%" mb={{ base: '10rem', md: '5rem', lg: '3rem' }} pos="relative">
      <AspectRatioBox
        ratio={16 / 9}
        mt={{ base: 0, lg: 'calc(-1*154px + 20px)' }}
        mx={{ base: 0, lg: '20px' }}
        maxH={{ base: '63vmin', lg: '70vh' }}
        pos="relative"
        borderRadius={{ base: 0, lg: '6px' }}
      >
        <LoadingPlaceholder h="full" w="full" />
      </AspectRatioBox>
      <PseudoBox position="absolute" bottom={{ base: '-9.5rem', md: '-4.5rem', lg: '-2.5rem' }} width="100%">
        <Scroller>
          {[...Array(10).keys()].map(key => {
            return (
              <SquareLogoLoadingPlaceholder
                key={key}
                w={squarePlugWidthMedium}
                h={squarePlugWidthMedium}
                mr={{ base: 4, md: 6 }}
              />
            )
          })}
        </Scroller>
      </PseudoBox>
    </PseudoBox>
  )
}

export default MultiHeroLoadingPlaceholder
