import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'
import PlugImage from '../../Plug/PlugImage'
import PlugTitle from '../../Plug/PlugTitle'
import PlugBody from '../../Plug/PlugBody'
import SimpleTextWrapper from './componentParts/SimpleTextWrapper'
import SimplePlugWrapper from './componentParts/SimplePlugWrapper'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import PlugLink from '../../Plug/PlugLink/PlugLink'
import PlugPlayPauseProgressStaticButton from '../../Plug/PlugPlayPauseProgressButton/PlugPlayPauseProgressStaticButton'

const noop = () => {}

const SimplePlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      srcSet,
      title,
      description,
      type,
      episodeId,
      seriesId,
      seasonId,
      showPlay,
      channelId,
      startTime,
      accessibilityLabel,
      children,
      onClick = noop,
      onPlayClick = noop,
      ...props
    },
    ref
  ) => {
    const { ariaHiddenMaybe, ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)
    return (
      <SimplePlugWrapper showPlay={showPlay} {...props} ref={ref}>
        <Box position="relative" className="simple-image-wrapper">
          <PlugLink
            type={type}
            tabIndex="-1"
            aria-hidden="true"
            display="block"
            delay={1}
            to={linkUrl}
            onClick={onClick}
          >
            <PlugImage ratio={16 / 9} src={imageUrl} srcSet={srcSet} />
          </PlugLink>
        </Box>
        <SimpleTextWrapper {...ariaLabelMaybe} type={type} as={PlugLink} delay={0} to={linkUrl} onClick={onClick}>
          <PlugTitle {...ariaHiddenMaybe}>{title}</PlugTitle>
          <PlugBody {...ariaHiddenMaybe} lines={3}>
            {description}
          </PlugBody>
        </SimpleTextWrapper>
        {showPlay && (
          <Box pos="absolute" bottom="0">
            <PlugPlayPauseProgressStaticButton
              accessibilityLabel={accessibilityLabel}
              type={type}
              episodeId={episodeId}
              seriesId={seriesId}
              channelId={channelId}
              seasonId={seasonId}
              startTime={startTime}
              linkUrl={linkUrl}
              w="44px"
              h="44px"
              onPlayClick={onPlayClick}
            />
          </Box>
        )}
        {children}
      </SimplePlugWrapper>
    )
  }
)

SimplePlug.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  srcSet: PropTypes.string,
  type: PropTypes.string,
  episodeId: PropTypes.string,
  channelId: PropTypes.string,
  seriesId: PropTypes.string,
  seasonId: PropTypes.string,
  startTime: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onPlayClick: PropTypes.func,
  showPlay: PropTypes.bool
}

SimplePlug.displayName = 'SimplePlug'

export default SimplePlug
