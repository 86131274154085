import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../Box'

const EditorialTextWrapper = ({ children, ...props }) => {
  return (
    <Box flex="1 0 auto" w="100%" display="block" pt={2} {...props}>
      {children}
    </Box>
  )
}

EditorialTextWrapper.propTypes = {
  children: PropTypes.node
}

export default EditorialTextWrapper
