import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../Box'
import { CHANNEL } from '../../../pages/FrontPage/helpers/plugTypes'
import { useColors } from '../../theme/Color'
import { css } from '@emotion/react'
import breakpoints from '../../theme/breakpoints'

function progressToStrokeOffset(percentage, circumference) {
  return circumference - (percentage / 100) * circumference
}

function calculateCircle(buttonSize) {
  const radius = (parseInt(buttonSize.replace('px', '')) + 8) / 2
  const circumference = 2 * Math.PI * radius
  const centerxy = radius + 2
  return {
    radius,
    circumference,
    cx: centerxy,
    cy: centerxy
  }
}

const PlugProgressCircle = ({ type, size, percentage = 0 }) => {
  const lg = size?.lg || size?.base || size || '44px'
  const base = size?.base || size || '44px'
  const { radius, circumference, cx, cy } = calculateCircle(lg)
  const mobilSizes = calculateCircle(base)
  const colors = useColors()

  return (
    <Box
      as="svg"
      position="absolute"
      top="-6px"
      left="-6px"
      w={{ base: `calc(${base} + 8px + 6px)`, lg: `calc(${lg} + 8px + 6px)` }}
      h={{ base: `calc(${base} + 8px + 6px)`, lg: `calc(${lg} + 8px + 6px)` }}
      className="PlayProgressCircle"
    >
      <Box
        as="circle"
        stroke={type === CHANNEL ? colors.live.mediumLight10 : colors.main.light}
        strokeWidth={2.5}
        fill="transparent"
        transition="stroke-dashoffset 0.35s"
        transform="rotate(-90deg)"
        css={css`
          r: ${mobilSizes.radius};
          cx: ${mobilSizes.cx};
          cy: ${mobilSizes.cy};
          transform-origin: ${mobilSizes.cx}px ${mobilSizes.cy}px;
          stroke-dasharray: ${mobilSizes.circumference} ${mobilSizes.circumference};
          stroke-dashoffset: ${progressToStrokeOffset(percentage, mobilSizes.circumference)};
          @media screen and (min-width: ${breakpoints.lg}) {
            r: ${radius};
            cx: ${cx};
            cy: ${cy};
            transform-origin: ${cx}px ${cy}px;
            stroke-dasharray: ${circumference} ${circumference};
            stroke-dashoffset: ${progressToStrokeOffset(percentage, circumference)};
          }
        `}
      />
    </Box>
  )
}

PlugProgressCircle.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  percentage: PropTypes.number
}

export default PlugProgressCircle

/*
strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={progressToStrokeOffset(percentage, circumference)}
        transformOrigin="50% 50%"
        transform="rotate(-90deg)"
        transition="stroke-dashoffset 0.35s"


                

*/
