import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import differenceInSeconds from 'date-fns/difference_in_seconds'
import getDay from 'date-fns/get_day'
import Text from '../Text'
import localeFormat from '../../common/localeFormat'
import { useColors } from '../theme/Color'

const indexToNorwegianDay = index => {
  switch (index) {
    case 0:
      return 'Søndag'
    case 1:
      return 'Mandag'
    case 2:
      return 'Tirsdag'
    case 3:
      return 'Onsdag'
    case 4:
      return 'Torsdag'
    case 5:
      return 'Fredag'
    case 6:
      return 'Lørdag'
  }
}

const makeTimelabelForMoreThan30Minutes = (startTime, diffDays) => {
  if (diffDays === 0) {
    return `Starter ${localeFormat(startTime, 'HH:mm')}`
  } else if (diffDays === 1) {
    return 'I morgen'
  } else if (diffDays < 7) {
    return indexToNorwegianDay(getDay(startTime))
  } else {
    return 'Kommer'
  }
}

const PlugDirectLabel = ({ startTime, ...props }) => {
  const colors = useColors()
  const [diffDays, setDiffDays] = useState(differenceInCalendarDays(startTime, new Date()))
  const [diffMin, setDiffMin] = useState(differenceInMinutes(startTime, new Date()) + 1)
  const [diffSec, setDiffSec] = useState(differenceInSeconds(startTime, new Date()))

  useEffect(() => {
    const interval = setInterval(() => {
      setDiffDays(differenceInCalendarDays(startTime, new Date()))
      setDiffMin(differenceInMinutes(startTime, new Date()) + 1)
      setDiffSec(differenceInSeconds(startTime, new Date()))
    }, 1000)

    return () => clearInterval(interval)
  }, [startTime])

  if (diffMin > 30) {
    return (
      <Text
        display="inline-block"
        weight="semibold"
        bg={colors.main.contrastLight}
        color={colors.main.dark}
        size="sm"
        px="2"
        py="0.125rem"
        rounded="md"
        textTransform="uppercase"
        shadow="float"
        {...props}
      >
        {makeTimelabelForMoreThan30Minutes(startTime, diffDays)}
      </Text>
    )
  } else if (diffMin <= 30 && diffSec > 0) {
    return (
      <Text
        display="inline-block"
        weight="semibold"
        bg={colors.main.contrastLight}
        color={colors.live.mediumLight10}
        size="sm"
        px="2"
        py="0.125rem"
        rounded="md"
        textTransform="uppercase"
        shadow="float"
        {...props}
      >
        {`Starter om ${diffMin} min`}
      </Text>
    )
  } else {
    return (
      <Text
        display="inline-block"
        weight="semibold"
        bg={colors.live.mediumLight10}
        size="sm"
        px="2"
        py="0.125rem"
        rounded="md"
        textTransform="uppercase"
        shadow="float"
        {...props}
      >
        Direkte nå
      </Text>
    )
  }
}

PlugDirectLabel.propTypes = {
  startTime: PropTypes.string
}

export default PlugDirectLabel
