import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import PlugImage from '../../Plug/PlugImage'
import PseudoBox from '../../PseudoBox'
import { hoverMovementStylesFromLg } from '../styles/hoverMovementStyles'
import useAccessibilityLabelToAttributes from '../../Plug/hooks/useAccessibilityLabelToAttributes'
import { focusVisibleStyles, focusWithinStyles } from '../styles/focusStyles'
import { plugButtonsHoverStyles } from '../../Plug/PlugPlayPauseProgressButton/plugButtonStyles'
import { css } from '@emotion/react'
import PlugEpisodeMenu from '../../Plug/PlugEpisodeMenu/PlugEpisodeMenu'
import breakpoints from '../../theme/breakpoints'
import { squarePlugWidthMedium } from '../styles/plugWidths'
import PlugLink from '../../Plug/PlugLink/PlugLink'

const noop = () => {}

const hoverStylesPluss = css`
  scroll-snap-align: start;
  @media all and (min-width: ${breakpoints.lg}) {
    &:hover {
      .MultiheroPlugImg {
        filter: none;
        transition: 0.25s filter cubic-bezier(0.12, 0, 0, 1);
      }
    }
  }
`

const MultiHeroPlug = forwardRef(
  (
    {
      linkUrl,
      imageUrl,
      accessibilityLabel,
      onClick = noop,
      onHover = noop,
      onSelectAsActive = noop,
      isActive,
      isMobile,
      type,
      episodeId,
      seriesId,
      channelId,
      episodeTitle,
      seriesTitle,
      channelTitle,
      startTime,
      onChangeQueue = noop,
      onShareContent = noop,
      onViewQueue = noop,
      onRemoveFromQueue = noop,
      onUnFavourite = noop,
      onFavourite = noop,
      onUnMarkCompleted = noop,
      onMarkCompleted = noop,
      onClickDuplicate = noop,
      onPlayClick = noop,
      onClickTitle = noop,
      onDialogOpen = noop,
      children,
      ...props
    },
    ref
  ) => {
    const { ariaLabelMaybe } = useAccessibilityLabelToAttributes(accessibilityLabel)

    return (
      <PseudoBox
        position="relative"
        flex="0 0 auto"
        css={[
          hoverMovementStylesFromLg,
          focusVisibleStyles,
          focusWithinStyles,
          plugButtonsHoverStyles,
          hoverStylesPluss
        ]}
        ml={{ base: 3, md: 6 }}
        w={squarePlugWidthMedium}
        h={squarePlugWidthMedium}
        ref={ref}
        {...props}
      >
        <PlugLink
          onClick={onClick}
          as={Link}
          to={linkUrl}
          type={type}
          w="100%"
          h="100%"
          opacity="1 !important"
          onMouseOver={() => !isMobile && onHover()}
          onFocus={() => !isMobile && onHover()}
          {...ariaLabelMaybe}
        >
          <PlugImage
            className="MultiheroPlugImg"
            ratio={1}
            src={imageUrl}
            css={css`
              filter: ${isActive ? 'none' : 'brightness(0.7)'};
              transition: 0.25s filter cubic-bezier(0.12, 0, 0, 1);
            `}
          />
        </PlugLink>

        <PlugEpisodeMenu
          episodeId={episodeId}
          seriesId={seriesId}
          episodeTitle={episodeTitle}
          seriesTitle={seriesTitle}
          channelTitle={channelTitle}
          type={type}
          linkUrl={linkUrl}
          position="absolute"
          top="6px"
          right="2px"
          onChangeQueue={onChangeQueue}
          oonShareContent={onShareContent}
          onViewQueue={onViewQueue}
          onRemoveFromQueue={onRemoveFromQueue}
          onUnFavourite={onUnFavourite}
          onFavourite={onFavourite}
          onUnMarkCompleted={onUnMarkCompleted}
          onMarkCompleted={onMarkCompleted}
          onClickDuplicate={onClickDuplicate}
          onClickTitle={onClickTitle}
          onDialogOpen={onDialogOpen}
        />
      </PseudoBox>
    )
  }
)
MultiHeroPlug.propTypes = {
  type: PropTypes.string,
  episodeId: PropTypes.string,
  seriesId: PropTypes.string,
  channelId: PropTypes.string,
  episodeTitle: PropTypes.string,
  seriesTitle: PropTypes.string,
  channelTitle: PropTypes.string,
  startTime: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  accessibilityLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onSelectAsActive: PropTypes.func,
  onChangeQueue: PropTypes.func,
  onShareContent: PropTypes.func,
  onViewQueue: PropTypes.func,
  onRemoveFromQueue: PropTypes.func,
  onUnFavourite: PropTypes.func,
  onFavourite: PropTypes.func,
  onUnMarkCompleted: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  onClickDuplicate: PropTypes.func,
  onPlayClick: PropTypes.func,
  onClickTitle: PropTypes.func,
  onDialogOpen: PropTypes.func,
  isActive: PropTypes.bool,
  isMobile: PropTypes.bool
}

MultiHeroPlug.displayName = 'MultiHeroPlug'

export default MultiHeroPlug
