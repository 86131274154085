import logger from '../../../logger'
import {
  CHANNEL,
  EPISODE,
  PODCAST,
  PODCAST_EPISODE,
  SERIES,
  STANDALONE_PROGRAM,
  PODCAST_SEASON,
  LINK,
  PAGE
} from './plugTypes'

const extractProgramTitles = plug => {
  switch (plug.type) {
    case CHANNEL: {
      return {
        channelTitle: plug[plug.type].channelTitle
      }
    }
    case EPISODE: {
      return {
        seriesTitle: plug[plug.type].seriesTitle,
        episodeTitle: plug[plug.type].episodeTitle
      }
    }
    case SERIES: {
      return {
        seriesTitle: plug[plug.type].seriesTitle
      }
    }
    case STANDALONE_PROGRAM: {
      return {
        episodeTitle: plug[plug.type].programTitle
      }
    }
    case PODCAST: {
      return {
        seriesTitle: plug[plug.type].podcastTitle
      }
    }
    case PODCAST_EPISODE: {
      return {
        seriesTitle: plug[plug.type].podcastTitle,
        episodeTitle: plug[plug.type].podcastEpisodeTitle
      }
    }
    case PODCAST_SEASON: {
      return {
        seriesTitle: plug[plug.type].podcastTitle
      }
    }
    case LINK: {
      return {
        title: plug.title
      }
    }
    case PAGE: {
      return {
        title: plug.title
      }
    }
    default:
      logger.warn(`Plug type ${plug.type} is missing in extractProgramTitles`)
      return ''
  }
}

export default extractProgramTitles
