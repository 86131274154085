import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../Box'

const SimpleTextWrapper = ({ children, ...props }) => {
  return (
    <Box flex="1 0 auto" w="100%" pt={2} display="block" {...props}>
      {children}
    </Box>
  )
}

SimpleTextWrapper.propTypes = {
  children: PropTypes.node
}

export default SimpleTextWrapper
