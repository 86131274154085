import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../../Box'
import { initHoverColorStyles } from '../../styles/hoverColorStyles'
import { hoverMovementStylesFromLg } from '../../styles/hoverMovementStyles'
import { plugButtonsHoverStyles } from '../../../Plug/PlugPlayPauseProgressButton/plugButtonStyles'
import { focusVisibleStyles, focusWithinStyles } from '../../styles/focusStyles'
import { useColors } from '../../../theme/Color'

const LandscapeLogoPlugWrapper = forwardRef(({ children, ...props }, ref) => {
  const hoverColorStyles = initHoverColorStyles(useColors().main, '.PlugTitle')
  return (
    <Box
      className="LandscapeLogoPlugWrapper"
      position="relative"
      flex="0 0 auto"
      css={[hoverMovementStylesFromLg, plugButtonsHoverStyles, hoverColorStyles, focusWithinStyles, focusVisibleStyles]}
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  )
})

LandscapeLogoPlugWrapper.propTypes = {
  children: PropTypes.node
}

LandscapeLogoPlugWrapper.displayName = 'LandscapeLogoPlugWrapper'

export default LandscapeLogoPlugWrapper
