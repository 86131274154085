import React from 'react'
import Box from '../../RadioUI/Box'
import PropTypes from 'prop-types'
import ContentWidth from '../../RadioUI/ContentWidth'
import Text from '../../RadioUI/Text'

const SectionWrapper = ({ children, title, ...restProps }) => {
  return (
    <Box as="section" w="full" mb="10" {...restProps}>
      {title && (
        <ContentWidth>
          <Text as="h2" weight="semibold" size={['md', 'lg']} mb={2} data-test="discover-section">
            {title}
          </Text>
        </ContentWidth>
      )}
      {children}
    </Box>
  )
}

SectionWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}
export default SectionWrapper
